.MuiMultiSectionDigitalClockSection-item {
  min-width: auto !important;
}
.MuiMultiSectionDigitalClockSection-root::after {
  display: block;
  height: 0px !important;
}
.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  display: "none";
}

/* Default border color when the input is not focused or hovered */
/* .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: red; 
} */

/* Border color on hover */
/* .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: orange;
} */

/* Focused state (when clicking into the input) */
